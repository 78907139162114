module.exports = {
  "name": "aecs",
  "client": "CONS SUPERIEUR NAT EXPERTS COMPTAB",
  "description": "Mobile app for AECS 2022",
  "validLangs": ["fr"],
  "version": {
    "mainVersion": "1.0.0",
    "buildVersion": {
      "iOS": "34",
      "android": "35",
      "web": "37"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "11.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 31,
    "androidSigning": {
      "keyAlias": "mobilespot",
      "keyStorePassword": "jee1Uu0Hieloh7bi"
    },
    "app": "app-react"
  },
  "undeliveredFolders": ["source", "exports"],
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/cordova.git",
    "postCommands": ["cd cordova && npm install"],
    "branch": "inte/aecs2022/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "branch": "inte/aecs2022/main",
    "postCommands": ["cd mobigeo && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-aecs-0922.git",
    "branch": "inte/aecs2022/main",
    "dest": "mobigeo/mobigeo-customs"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/aecs2022/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-aecs-2022.git",
    "branch": "inte/aecs2022/main",
    "dest": "app-react/data",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  "crypto": false,
  "web": {}
};