import { Online, Présentiel } from 'data/config/profiles';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { navigateAfterClick } from 'src/pages/choose-profile/chooseProfileUtil';
/**
 * Choose profile page buttons configuration
 *
 * @param {object} actions - store connected actions
 */

export var get = function get() {
  function onClick(selectedProfile, actions, thenNavigateTo) {
    navigateAfterClick(selectedProfile, actions, thenNavigateTo);
  }

  return [{
    value: Présentiel,
    // reference for the rest of the app
    label: "profile.".concat(Présentiel),
    props: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Onsite.png'), ")"),
        backgroundPosition: '0% 50%',
        backgroundColor: '#a0aac0'
      }
    },
    onClick: onClick
  }, {
    value: Online,
    // reference for the rest of the app
    label: "profile.".concat(Online),
    props: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/online.png'), ")"),
        backgroundPosition: '0% 50%',
        backgroundColor: '#a0aac0'
      }
    },
    onClick: onClick
  }];
};
/**
 *  - Ads are displayed on ChooseProfilePage
 *  - Ad config depends on current user profile
 *  => Conclusion: on first display, it is not possible to display ad(s)
 *
 *  When the user profile is not known yet, the page will use this profile to display the ad(s)
 */

export var DEFAULT_AD_PROFILE = Online;